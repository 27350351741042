import fortmaticModule from "@web3-onboard/fortmatic";
import gnosisModule from "@web3-onboard/gnosis";
import injectedModule from "@web3-onboard/injected-wallets";
import keepkeyModule from "@web3-onboard/keepkey";
import walletConnectModule from "@web3-onboard/walletconnect";
import keystoneModule from "@web3-onboard/keystone";
import ledgerModule from "@web3-onboard/ledger";
import portisModule from "@web3-onboard/portis";
import torusModule from "@web3-onboard/torus";
import trezorModule from "@web3-onboard/trezor";
import initWalletConnect from "@web3-onboard/walletconnect";
import coinbaseModule from "@web3-onboard/coinbase";
import magicModule from "@web3-onboard/magic";
import { init } from "@web3-onboard/react";
import Onboard from "@web3-onboard/core";
import wagmi from "@web3-onboard/wagmi";

import blocknativeIcon from "./assets/images/racelogo.svg";
// import blocknativeLogo from "./assets/blocknativeLogo.svg";

const INFURA_KEY = "1886ff3d0a1143689424a8341cb75c66";

const injected = injectedModule();

const walletLink = coinbaseModule();

const walletConnect = initWalletConnect({
  projectId: "9ab3c65ab8829967bba6f99b37b2e868",
  dappUrl: "https://bridge.race.foundation/",
});

const portis = portisModule({
  // Replace with your apiKey
  apiKey: "b2b7586f-2b1e-4c30-a7fb-c2d1533b153b",
});

const fortmatic = fortmaticModule({
  // Replace with your apiKey
  apiKey: "pk_test_886ADCAB855632AA",
});

const torus = torusModule();
// const ledger = ledgerModule();
const keepkey = keepkeyModule();
const keystone = keystoneModule();
const gnosis = gnosisModule();

const trezorOptions = {
  email: "test@test.com",
  appUrl: "https://www.blocknative.com",
};
const trezor = trezorModule(trezorOptions);

const magic = magicModule({
  // Replace with your apiKey
  apiKey: "pk_live_02207D744E81C2BA",
});

export const initWeb3Onboard = () =>
  init({
    // An array of wallet modules that you would like to be presented to the user to select from when connecting a wallet.
    wagmi,
    connect: {
      autoConnectAllPreviousWallet: true,
    },
    wallets: [
      // ledger,
      // trezor,
      walletConnect,
      // keepkey,
      keystone,
      walletLink,
      injected,
      // magic,
      // fortmatic,
      // portis,
      // torus,
      gnosis,
    ],
    // An array of Chains that your app supports
    chains: [
      {
        // hex encoded string, eg '0x1' for Ethereum Mainnet
        id: "0x1a95",
        // string indicating chain namespace. Defaults to 'evm' but will allow other chain namespaces in the future
        namespace: "evm",
        // the native token symbol, eg ETH, BNB, MATIC
        token: "ETH",
        // used for display, eg Ethereum Mainnet
        label: "RACE MAINNET",
        // used for network requests
        rpcUrl: process.env.REACT_APP_L2_RPC_URL,
      },
      {
        // hex encoded string, eg '0x1' for Ethereum Mainnet
        id: "0x1",
        // string indicating chain namespace. Defaults to 'evm' but will allow other chain namespaces in the future
        namespace: "evm",
        // the native token symbol, eg ETH, BNB, MATIC
        token: "ETH",
        // used for display, eg Ethereum Mainnet
        label: "Ethereum",
        // used for network requests
        rpcUrl: process.env.REACT_APP_L1_RPC_URL,
      },
    ],
    appMetadata: {
      // The name of your dApp
      name: "RACE Bridge",
      // SVG icon string, with height or width (whichever is larger) set to 100% or a valid image URL
      icon: blocknativeIcon,
      // Optional wide format logo (ie icon and text) to be displayed in the sidebar of connect modal. Defaults to icon if not provided
      // logo: blocknativeLogo,
      // The description of your app
      description: "RACE Mainnet Bridge",
      // The url to a getting started guide for app
      gettingStartedGuide: "https://raceecosystem.gitbook.io/docs",
      // url that points to more information about app
      explore: "https://race.foundation/",
      // if your app only supports injected wallets and when no injected wallets detected, recommend the user to install some
      recommendedInjectedWallets: [
        {
          // display name
          name: "MetaMask",
          // link to download wallet
          url: "https://metamask.io",
        },
        { name: "Coinbase", url: "https://wallet.coinbase.com/" },
      ],
      // Optional - but allows for dapps to require users to agree to TOS and privacy policy before connecting a wallet
      agreement: {
        version: "1.0.0",
        termsUrl: "https://www.blocknative.com/terms-conditions",
        privacyUrl: "https://www.blocknative.com/privacy-policy",
      },
    },
    // example customising copy
    // i18n: {
    //   en: {
    //     connect: {
    //       selectingWallet: {
    //         header: 'custom text header'
    //       }
    //     }
    //   }
    // }
  });
