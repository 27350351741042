import React, { useEffect, useState } from "react";
import "../assets/style/deposit.scss";
import "../assets/style/withdraw.scss";
import { Form, Image, Spinner, Button } from "react-bootstrap";
import { Dai, Usdt, Usdc, Ethereum, Btc } from "react-web3-icons";
import { MdOutlineSecurity } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import Web3 from "web3";
import toIcn from "../assets/images/logo.png";
import {
  useAccount,
  useConnect,
  useNetwork,
  useSwitchNetwork,
  useBalance,
  useContractRead,
  writeContract,
  readContract,
} from "../hooks/wagmi";

import { IoMdWallet } from "react-icons/io";
import { HiSwitchHorizontal } from "react-icons/hi";
import metamask from "../assets/images/metamask.svg";
import erc721Abi from "../abis/erc721.json";
import personL1Bridge from "../abis/personL1Bridge.json";
// import metadata from "../abis/realAssets.prod.json";
import { useWalletClient } from "wagmi";
// import { writeContract, readContract } from "@wagmi/core";

import TabMenu from "./TabMenu";
import axios from "axios";
import { useConnectWallet } from "@web3-onboard/react";
const optimismSDK = require("@eth-optimism/sdk");
const ethers = require("ethers");

const etherProvider = new ethers.providers.JsonRpcProvider(
  process.env.REACT_APP_L2_RPC_URL
);

const clientToProviderSigner = async (client) => {
  const { account, chain, transport } = client;
  const network = {
    chainId: chain?.id,
    name: chain?.name,
    // ensAddress: chain?.contracts?.ensRegistry?.address,
  };
  // You can use whatever provider that fits your need here.
  const provider = new ethers.providers.Web3Provider(transport, network);
  const signer = await provider.getSigner(account?.address);
  return { provider, signer };
};

const Withdraw = ({ topTab, setTopTab }) => {
  const [ethValue, setEthValue] = useState("");
  const [sendToken, setSendToken] = useState("ETH");
  const [withdrawId, setWithdrawId] = useState(0);

  const [errorInput, setErrorInput] = useState("");
  const [checkMetaMask, setCheckMetaMask] = useState("");
  const [loader, setLoader] = useState(false);
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const [ids, setIds] = useState([]);
  const [nftImage, setNftImage] = useState("");
  // const { data: walletClient } = useWalletClient();

  const [RaceBalance, setRaceBalance] = useState(0);
  //console.log("topTabtopTab", topTab);
  // useEffect(()=>{
  //   setTopTab(localStorage.getItem("topTab"))
  // },[])
  const { connectWallet, disconnectWallet } = useConnect();

  const [metaMastError, setMetaMaskError] = useState("");
  const { switchNetwork } = useSwitchNetwork();

  const [{ wallet }] = useConnectWallet();
  const [provider, setProvider] = useState(null);
  useEffect(() => {
    if (wallet?.provider) {
      setProvider(new ethers.providers.Web3Provider(wallet.provider, "any"));
    }
  }, [wallet]);

  //========================================================== BALANCES =======================================================================

  useEffect(() => {
    setNftImage("");
    setErrorInput("");
  }, [withdrawId]);

  // const fetchImage = async () => {
  //   try {
  //     if (withdrawId) {
  //       const uri = await readContract({
  //         abi: erc721Abi,
  //         address: process.env.REACT_APP_L2_PERSONA_NFT,
  //         functionName: "tokenURI",
  //         args: [withdrawId],
  //       });

  //       const metadata = await axios.get(uri);
  //       setNftImage(metadata.data.image);
  //     }
  //   } catch (error) {}
  // };

  const { data } = useBalance({
    address: address,
    chainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
    watch: true,
  });
  const dataUSDT = useBalance({
    address: address,
    chainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
    token: process.env.REACT_APP_L2_USDT,
    watch: true,
  });
  const dataDAI = useBalance({
    address: address,
    chainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
    token: process.env.REACT_APP_L2_DAI,
    watch: true,
  });
  const dataUSDC = useBalance({
    address: address,
    chainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
    token: process.env.REACT_APP_L2_USDC,
    watch: true,
  });
  const datawBTC = useBalance({
    address: address,
    chainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
    token: process.env.REACT_APP_L2_wBTC,
    watch: true,
  });

  // const personaAccountBalance = useContractRead({
  //   abi: erc721Abi,
  //   address: process.env.REACT_APP_L2_PERSONA_NFT,
  //   functionName: "balanceOf",
  //   args: [address],
  //   account: address,
  //   enabled: address,
  // });

  // //console.log("personaAccountBalance", personaAccountBalance?.data);

  // useEffect(() => {
  //   const fetchUserTokenIds = async () => {
  //     const balance = await personaAccountBalance?.data;

  //     const calls = [];
  //     for (let index = 0; index < Number(balance); index++) {
  //       const call = readContract({
  //         abi: erc721Abi,
  //         address: process.env.REACT_APP_L2_PERSONA_NFT,
  //         functionName: "tokenOfOwnerByIndex",
  //         args: [address, index],
  //       });
  //       calls.push(call);
  //     }
  //     try {
  //       const result = await Promise.all(calls);
  //       //console.log("result", result);
  //       setIds(result);
  //     } catch (error) {
  //       //console.log("Error", error);
  //     }
  //   };

  //   if (
  //     personaAccountBalance &&
  //     chain &&
  //     Number(chain?.id) === Number(process.env.REACT_APP_L2_CHAIN_ID)
  //   ) {
  //     try {
  //       fetchUserTokenIds();
  //     } catch (error) {
  //       //console.log(error?.message);
  //     }
  //   }
  // }, [Number(personaAccountBalance), address, chain?.id]);

  // const { data: dataUSDTs, loading, errors } = useBalance({
  //   address: "0xEB71FA3084f1c3E2A66075760b3E3926F7843f34",
  //   chainId: 6806,
  //   token: "0x8315A87189C356F5e213ACBD39b74aDd45b80cd2",
  //   watch: true,
  // });

  // if (loading) {
  //   //console.log("Loading USDT balance...")
  // }

  // if (errors) {
  //   //console.log(`Error fetching USDT balance: ${error.message}`)
  // }

  // // Now you can safely access dataUSDT
  //  //console.log(dataUSDTs)
  ////========================================================== WITHDRAW =======================================================================

  const handleWithdraw = async () => {
    try {
      if (!ethValue) {
        setErrorInput("Please enter the amount");
      } else {
        if (!parseFloat(ethValue) > 0) {
          setErrorInput("Invalid Amount Entered!");
        } else {
          setErrorInput("");
          const l1Url = process.env.REACT_APP_L1_RPC_URL;
          // const { signer } = await clientToProviderSigner(walletClient);
          const l1Provider = new ethers.providers.JsonRpcProvider(l1Url, "any");
          const l1Signer = l1Provider.getSigner(address);
          const l2Signer = provider.getSigner(address);
          const zeroAddr = "0x".padEnd(42, "0");
          const l1Contracts = {
            StateCommitmentChain: zeroAddr,
            CanonicalTransactionChain: zeroAddr,
            BondManager: zeroAddr,
            AddressManager: process.env.REACT_APP_LIB_ADDRESSMANAGER,
            L1CrossDomainMessenger:
              process.env.REACT_APP_PROXY_OVM_L1CROSSDOMAINMESSENGER,
            L1StandardBridge: process.env.REACT_APP_PROXY_OVM_L1STANDARDBRIDGE,
            OptimismPortal: process.env.REACT_APP_OPTIMISM_PORTAL_PROXY,
            L2OutputOracle: process.env.REACT_APP_L2_OUTPUTORACLE_PROXY,
          };
          const bridges = {
            Standard: {
              l1Bridge: l1Contracts.L1StandardBridge,
              l2Bridge: "0x4200000000000000000000000000000000000010",
              Adapter: optimismSDK.StandardBridgeAdapter,
            },
            ETH: {
              l1Bridge: l1Contracts.L1StandardBridge,
              l2Bridge: "0x4200000000000000000000000000000000000010",
              Adapter: optimismSDK.ETHBridgeAdapter,
            },
          };
          const crossChainMessenger = new optimismSDK.CrossChainMessenger({
            contracts: {
              l1: l1Contracts,
            },
            bridges: bridges,
            l1ChainId: Number(process.env.REACT_APP_L1_CHAIN_ID),
            l2ChainId: Number(process.env.REACT_APP_L2_CHAIN_ID),
            l1SignerOrProvider: l1Signer,
            l2SignerOrProvider: l2Signer,
            bedrock: true,
          });
          //-------------------------------------------------------- SEND TOKEN VALUE -----------------------------------------------------------------

          try {
            if (sendToken == "ETH") {
              const weiValue = parseInt(
                ethers.utils.parseEther(ethValue)._hex,
                16
              );
              setLoader(true);
              const response = await crossChainMessenger.withdrawETH(
                weiValue.toString()
              );
              const logs = await response.wait();
              if (logs) {
                setLoader(false);
                setEthValue("");
              }
            }
            if (sendToken == "DAI") {
              var daiValue = Web3.utils.toWei(ethValue, "ether");
              setLoader(true);
              var depositTxn2 = await crossChainMessenger.withdrawERC20(
                process.env.REACT_APP_L1_DAI,
                process.env.REACT_APP_L2_DAI,
                daiValue
              );
              var receiptDAI = await depositTxn2.wait();
              if (receiptDAI) {
                setLoader(false);
                setEthValue("");
              }
            }

            if (sendToken == "USDT") {
              var usdtValue = parseInt(ethValue * 1000000);
              setLoader(true);
              var receiptUSDT = await crossChainMessenger.withdrawERC20(
                process.env.REACT_APP_L1_USDT,
                process.env.REACT_APP_L2_USDT,
                usdtValue
              );
              var getReceiptUSDT = await receiptUSDT.wait();
              if (getReceiptUSDT) {
                setLoader(false);
                setEthValue("");
              }
            }
            if (sendToken == "wBTC") {
              var wBTCValue = parseInt(ethValue * 100000000);
              setLoader(true);
              var receiptwBTC = await crossChainMessenger.withdrawERC20(
                process.env.REACT_APP_L1_wBTC,
                process.env.REACT_APP_L2_wBTC,
                wBTCValue
              );
              var getReceiptwBTC = await receiptwBTC.wait();
              if (getReceiptwBTC) {
                setLoader(false);
                setEthValue("");
              }
            }
            if (sendToken == "USDC") {
              var usdcValue = parseInt(ethValue * 1000000);
              setLoader(true);
              var receiptUSDC = await crossChainMessenger.withdrawERC20(
                process.env.REACT_APP_L1_USDC,
                process.env.REACT_APP_L2_USDC,
                usdcValue
              );
              var getReceiptUSDC = await receiptUSDC.wait();
              if (getReceiptUSDC) {
                setLoader(false);
                setEthValue("");
              }
            }
            //-------------------------------------------------------- SEND TOKEN VALUE END-----------------------------------------------------------------
            updateWallet();
          } catch (error) {
            setLoader(false);
            //console.log({ error }, 98);
          }
        }
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleSwitch = () => {
    try {
      const chainIdHex = `0x${parseInt(
        process.env.REACT_APP_L2_CHAIN_ID
      ).toString(16)}`;
      switchNetwork(chainIdHex);
    } catch (error) {
      //console.log(error);
    }
  };

  // const handlePersonaWithdraw = async () => {
  //   try {
  //     setLoader(true);
  //     const owner = await readContract({
  //       abi: erc721Abi,
  //       address: process.env.REACT_APP_L2_PERSONA_NFT,
  //       functionName: "ownerOf",
  //       args: [withdrawId],
  //     });
  //     if (owner?.toLowerCase() !== address.toLowerCase()) {
  //       setErrorInput("Not Owner");
  //       setLoader(false);
  //       return;
  //     }

  //     const { hash: txHash } = await writeContract({
  //       address: process.env.REACT_APP_L2_PERSONA_BRIDGE,
  //       abi: personL1Bridge,
  //       functionName: "bridgePersona",
  //       args: [withdrawId, 600000],
  //     });

  //     //console.log("txHash", txHash);
  //     const receipt = await etherProvider.waitForTransaction(txHash);
  //     //console.log(receipt);
  //     setLoader(false);
  //   } catch (error) {
  //     //console.log("Error", error.message);
  //     setLoader(false);
  //   }
  // };

  ////========================================================== HANDLE CHANGE =======================================================================
  const [checkDisabled, setCheckDisabled] = useState(false);

  const handleChange = (e) => {
    if (sendToken == "ETH") {
      if (Number(data?.formatted) < Number(e.target.value)) {
        setCheckDisabled(true);
        setErrorInput("Insufficient ETH balance.");
      } else {
        setCheckDisabled(false);
        setErrorInput("");
      }
      setEthValue(e.target.value);
    }
    if (sendToken == "DAI") {
      if (Number(dataDAI.data?.formatted) < e.target.value) {
        setCheckDisabled(true);
        setErrorInput("Insufficient DAI balance.");
      } else {
        setCheckDisabled(false);
        setErrorInput("");
      }
      setEthValue(e.target.value);
    }
    if (sendToken == "USDT") {
      if (Number(dataUSDT.data?.formatted) < e.target.value) {
        setCheckDisabled(true);
        setErrorInput("Insufficient DAI balance.");
      } else {
        setCheckDisabled(false);
        setErrorInput("");
      }
      setEthValue(e.target.value);
    }
    if (sendToken == "wBTC") {
      if (Number(datawBTC.data?.formatted) < e.target.value) {
        setCheckDisabled(true);
        setErrorInput("Insufuficient wBTC balance.");
      } else {
        setCheckDisabled(false);
        setErrorInput("");
      }
      setEthValue(e.target.value);
    }
    if (sendToken == "USDC") {
      if (Number(dataUSDC.data?.formatted) < e.target.value) {
        setCheckDisabled(true);
        setErrorInput("Insufficient USDC balance.");
      } else {
        setCheckDisabled(false);
        setErrorInput("");
      }
      setEthValue(e.target.value);
    }
  };

  // const demo = useBalance({ address: address, chainId: Number(process.env.REACT_APP_L2_CHAIN_ID), watch: true })
  // ============= For Format balance =========================
  const formatBalance = (rawBalance) => {
    const balance = (parseInt(rawBalance) / 1000000000000000000).toFixed(6);
    return balance;
  };
  // ============= Get and update balance =========================
  const updateWallet = async () => {
    // const l1Provider = new ethers.providers.JsonRpcProvider(l1Url, "any");
    // const l2Provider = new ethers.providers.Web3Provider("https://racetestnet.io/");
    // // const l1Signer = l1Provider.getSigner(address)
    // const l2Signer = l2Provider.getSigner(address)
    // const balance_user =  await l2Provider.getBalance(l2Signer.address)
    // //console.log({balance_user});
    // return
    try {
      const balance = formatBalance(
        await window.ethereum.request({
          method: "eth_getBalance",
          params: [address, "latest"],
        })
      );
      setRaceBalance(balance);
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    updateWallet();
    // //console.log("data?.formatted", demo.data?.formatted);
    // //console.log("data", data);
    // //console.log("address", address, "Number(process.env.REACT_APP_L2_CHAIN_ID)", Number(process.env.REACT_APP_L2_CHAIN_ID));
  }, [data]);

  const handleWithdrawnNFtclikc = (event) => {
    event.preventDefault();
    // Add your logic here
    //console.log("Fetch NFT button clicked");
  };
  return (
    <>
      <div className="bridge_wrap">
        {/* <TabMenu /> */}
        {topTab === "token" && (
          <>
            <section className="deposit_wrap">
              <div className="withdraw_title_wrap">
                <div className="withdraw_title_icn">
                  <MdOutlineSecurity />
                </div>
                <div className="withdraw_title_content">
                  <h3>Use the official bridge</h3>
                  <p>This usually takes 7 days</p>
                  <p>Bridge any token to Ethereum Mainnet</p>
                </div>
              </div>
              <div className="deposit_price_wrap">
                <div className="deposit_price_title">
                  <p>From</p>
                  <h5>
                    <Image src={toIcn} alt="To icn" fluid /> Race
                  </h5>
                </div>
                <div className="deposit_input_wrap">
                  <Form>
                    <div className="deposit_inner_input">
                      <Form.Control
                        type="number"
                        name="eth_value"
                        value={ethValue}
                        onChange={handleChange}
                        placeholder="0"
                        min="0"
                        step="any"
                      />
                      <Form.Select
                        aria-label="Default select example"
                        className="select_wrap"
                        onChange={({ target }) => setSendToken(target.value)}
                      >
                        <option>ETH</option>
                        <option value="DAI">DAI</option>
                        <option value="USDT">USDT</option>
                        <option value="wBTC">wBTC</option>
                        <option value="USDC">USDC</option>
                      </Form.Select>
                    </div>
                    <div className="input_icn_wrap">
                      {sendToken == "ETH" ? (
                        <span className="input_icn">
                          <Ethereum style={{ fontSize: "1.5rem" }} />
                        </span>
                      ) : sendToken == "DAI" ? (
                        <span className="input_icn">
                          <Dai style={{ fontSize: "1.5rem" }} />
                        </span>
                      ) : sendToken == "USDT" ? (
                        <span className="input_icn">
                          <Usdt style={{ fontSize: "1.5rem" }} />
                        </span>
                      ) : sendToken == "wBTC" ? (
                        <span className="input_icn">
                          <Btc style={{ fontSize: "1.5rem" }} />
                        </span>
                      ) : (
                        <span className="input_icn">
                          <Usdc style={{ fontSize: "1.5rem" }} />
                        </span>
                      )}
                    </div>
                  </Form>
                </div>
                {errorInput && (
                  <small className="text-danger">{errorInput}</small>
                )}
                {sendToken === "ETH" ? (
                  address && (
                    <p className="wallet_bal mt-2">
                      Balance: {Number(data?.formatted).toFixed(5)} ETH
                    </p>
                  )
                ) : sendToken === "DAI" ? (
                  address && (
                    <p className="wallet_bal mt-2">
                      Balance: {Number(dataDAI.data?.formatted).toFixed(5)} DAI
                    </p>
                  )
                ) : sendToken == "USDT" ? (
                  address && (
                    <p className="wallet_bal mt-2">
                      Balance: {Number(dataUSDT.data?.formatted).toFixed(5)}{" "}
                      USDT
                    </p>
                  )
                ) : sendToken === "wBTC" ? (
                  address && (
                    <p className="wallet_bal mt-2">
                      Balance: {Number(datawBTC.data?.formatted).toFixed(5)}{" "}
                      wBTC
                    </p>
                  )
                ) : (
                  <p className="wallet_bal mt-2">
                    Balance: {Number(dataUSDC.data?.formatted).toFixed(5)} USDC
                  </p>
                )}
              </div>
              <div className="deposit_details_wrap">
                <div className="deposit_details">
                  <p>To:</p>
                  <h5>
                    <FaEthereum /> Ethereum
                  </h5>
                </div>
                <div className="withdraw_bal_sum">
                  {sendToken == "ETH" ? (
                    <span className="input_icn">
                      <Ethereum style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : sendToken == "DAI" ? (
                    <span className="input_icn">
                      <Dai style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : sendToken == "USDT" ? (
                    <span className="input_icn">
                      <Usdt style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : sendToken == "wBTC" ? (
                    <span className="input_icn">
                      <Btc style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : (
                    <span className="input_icn">
                      <Usdc style={{ fontSize: "1.5rem" }} />
                    </span>
                  )}
                  <p>
                    You’ll receive: {ethValue ? ethValue : "0"} {sendToken}
                  </p>
                  <div></div>
                  {/* <span className='input_title'>ETH</span> */}
                </div>
              </div>
              <div className="deposit_btn_wrap">
                {checkMetaMask === true ? (
                  <a
                    className="btn deposit_btn"
                    href="https://metamask.io/"
                    target="_blank"
                  >
                    <Image src={metamask} alt="metamask icn" fluid /> Please
                    Install Metamask Wallet
                  </a>
                ) : !isConnected ? (
                  <button
                    className="btn deposit_btn"
                    onClick={() => connectWallet()}
                  >
                    <IoMdWallet />
                    Connect Wallet
                  </button>
                ) : Number(chain.id) !==
                  Number(process.env.REACT_APP_L2_CHAIN_ID) ? (
                  <button className="btn deposit_btn" onClick={handleSwitch}>
                    <HiSwitchHorizontal />
                    Switch to RACE Mainnet
                  </button>
                ) : checkDisabled ? (
                  <button className="btn deposit_btn" disabled={true}>
                    Withdraw
                  </button>
                ) : (
                  <button
                    className="btn deposit_btn"
                    onClick={handleWithdraw}
                    disabled={loader ? true : false}
                  >
                    {loader ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Withdraw"
                    )}
                  </button>
                )}
              </div>
              {metaMastError && (
                <small className="d-block text-danger text-center mt-2">
                  {metaMastError}
                </small>
              )}
            </section>
          </>
        )}
        {topTab === "nifty" && (
          <>
            <section className="deposit_wrap">
              <div className="withdraw_title_wrap">
                <div className="withdraw_title_icn">
                  <MdOutlineSecurity />
                </div>
                <div className="withdraw_title_content">
                  <h3>Use the official bridge</h3>
                  <p>This usually takes 7 days</p>
                  <p>Bridge any token to Ethereum Mainnet</p>
                </div>
              </div>
              <div className="deposit_price_wrap">
                <div className="deposit_price_title">
                  <p>From</p>
                  <h5>
                    <Image src={toIcn} alt="To icn" fluid /> Race
                  </h5>
                </div>
                <div className="deposit_input_wrap">
                  <Form>
                    <div className="deposit_inner_input">
                      <Form.Control
                        type="number"
                        // value={ethValue}
                        // onChange={handleChange}
                        placeholder="Contract Address"
                        min="0"
                        step="any"
                      />
                    </div>
                    <br />
                    <div className="deposit_inner_input">
                      <Form.Control
                        type="number"
                        name="eth_value"
                        placeholder="Token Id"
                        min="0"
                        step="any"
                      />
                      <Form.Select
                        aria-label="Default select example"
                        className="select_wrap"
                        onChange={({ target }) => setSendToken(target.value)}
                      >
                        <option>ID</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </Form.Select>
                    </div>
                    <br />

                    <Button
                      type="button"
                      onClick={handleWithdrawnNFtclikc}
                      style={{
                        backgroundColor: "#074870",
                        borderColor: "#074870",
                        borderRadius: "25px",
                      }}
                    >
                      Fetch NFT
                    </Button>
                    {/* <div className='input_icn_wrap'>
                  {sendToken == "ETH" ? <span className='input_icn'><Ethereum style={{ fontSize: '1.5rem' }} /></span> : sendToken == "DAI" ? <span className='input_icn'><Dai style={{ fontSize: '1.5rem' }} /></span> : sendToken == "USDT" ? <span className='input_icn'><Usdt style={{ fontSize: '1.5rem' }} /></span> : sendToken == "wBTC" ? <span className='input_icn'><Btc style={{ fontSize: '1.5rem' }} /></span> : <span className='input_icn'><Usdc style={{ fontSize: '1.5rem' }} /></span>}
                </div> */}
                  </Form>
                </div>

                {sendToken === "ETH" ? (
                  address && (
                    <p className="wallet_bal mt-2">
                      Balance: {Number(data?.formatted).toFixed(5)} ETH
                    </p>
                  )
                ) : sendToken === "DAI" ? (
                  address && (
                    <p className="wallet_bal mt-2">
                      Balance: {Number(dataDAI.data?.formatted).toFixed(5)} DAI
                    </p>
                  )
                ) : sendToken == "USDT" ? (
                  address && (
                    <p className="wallet_bal mt-2">
                      Balance: {Number(dataUSDT.data?.formatted).toFixed(5)}{" "}
                      USDT
                    </p>
                  )
                ) : sendToken === "wBTC" ? (
                  address && (
                    <p className="wallet_bal mt-2">
                      Balance: {Number(datawBTC.data?.formatted).toFixed(5)}{" "}
                      wBTC
                    </p>
                  )
                ) : (
                  <p className="wallet_bal mt-2">
                    Balance: {Number(dataUSDC.data?.formatted).toFixed(5)} USDC
                  </p>
                )}
              </div>
              <div className="deposit_details_wrap">
                <div className="deposit_details">
                  <p>To:</p>
                  <h5>
                    <FaEthereum /> Ethereum
                  </h5>
                </div>
                <div className="withdraw_bal_sum">
                  {sendToken == "ETH" ? (
                    <span className="input_icn">
                      <Ethereum style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : sendToken == "DAI" ? (
                    <span className="input_icn">
                      <Dai style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : sendToken == "USDT" ? (
                    <span className="input_icn">
                      <Usdt style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : sendToken == "wBTC" ? (
                    <span className="input_icn">
                      <Btc style={{ fontSize: "1.5rem" }} />
                    </span>
                  ) : (
                    <span className="input_icn">
                      <Usdc style={{ fontSize: "1.5rem" }} />
                    </span>
                  )}
                  <p>
                    You’ll receive: {ethValue ? ethValue : "0"} {sendToken}
                  </p>
                  <div></div>
                  {/* <span className='input_title'>ETH</span> */}
                </div>
              </div>
              <div className="deposit_btn_wrap">
                {checkMetaMask === true ? (
                  <a
                    className="btn deposit_btn"
                    href="https://metamask.io/"
                    target="_blank"
                  >
                    <Image src={metamask} alt="metamask icn" fluid /> Please
                    Install Metamask Wallet
                  </a>
                ) : !isConnected ? (
                  <button
                    className="btn deposit_btn"
                    onClick={() => connectWallet()}
                  >
                    <IoMdWallet />
                    Connect Wallet
                  </button>
                ) : Number(chain.id) !==
                  Number(process.env.REACT_APP_L2_CHAIN_ID) ? (
                  <button className="btn deposit_btn" onClick={handleSwitch}>
                    <HiSwitchHorizontal />
                    Switch to RACE Mainnet
                  </button>
                ) : checkDisabled ? (
                  <button className="btn deposit_btn" disabled={true}>
                    Withdraw
                  </button>
                ) : (
                  <button
                    className="btn deposit_btn"
                    onClick={handleWithdraw}
                    disabled={loader ? true : false}
                  >
                    {loader ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Withdraw"
                    )}
                  </button>
                )}
              </div>
              {metaMastError && (
                <small className="d-block text-danger text-center mt-2">
                  {metaMastError}
                </small>
              )}
            </section>
          </>
        )}
        {/* {topTab === "persona" && (
          <>
            <section className="deposit_wrap">
              <div className="withdraw_title_wrap">
                <div className="withdraw_title_icn">
                  <MdOutlineSecurity />
                </div>
                <div className="withdraw_title_content">
                  <p>This usually takes 7 days</p>
                  <p>Bridge any Persona to Ethereum Mainnet</p>
                </div>
              </div>
              <div className="deposit_price_wrap">
                <div className="deposit_price_title">
                  <p>From</p>
                  <h5>
                    <Image src={toIcn} alt="To icn" fluid /> Race
                  </h5>
                </div>
                <div className="deposit_input_wrap">
                  <Form>
                    <div className="deposit_inner_input">
                      <Form.Control
                        type="number"
                        value={withdrawId}
                        onChange={(e) => setWithdrawId(e.target.value)}
                        placeholder="Token Id"
                        min="0"
                        step="any"
                      />

                      <Form.Select
                        aria-label="Default select example"
                        className="select_wrap"
                        onChange={({ target }) => setWithdrawId(target.value)}
                        value="ID"
                      >
                        <>
                          <option value={0}>ID</option>
                          {ids.map((item, index) => (
                            <option key={index} value={Number(item)}>
                              {Number(item)}
                            </option>
                          ))}
                        </>
                      </Form.Select>
                    </div>

                    <br />

                    <Button
                      style={{
                        backgroundColor: "#074870",
                        borderColor: "#074870",
                        borderRadius: "10px",
                      }}
                      onClick={fetchImage}
                    >
                      Fetch NFT
                    </Button>
                  </Form>

                  {nftImage && (
                    <img
                      src={nftImage}
                      style={{
                        width: "213px",
                        height: "156px",
                        marginTop: "13px",
                      }}
                      alt="Persona NFT"
                    />
                  )}
                </div>
                {errorInput && (
                  <small className="text-danger">{errorInput}</small>
                )}
              </div>
              <div className="deposit_details_wrap">
                <div className="deposit_details">
                  <p>To:</p>
                  <h5>
                    <FaEthereum /> Ethereum
                  </h5>
                </div>
              </div>
              <div className="deposit_btn_wrap">
                {checkMetaMask === true ? (
                  <a
                    className="btn deposit_btn"
                    href="https://metamask.io/"
                    target="_blank"
                  >
                    <Image src={metamask} alt="metamask icn" fluid /> Please
                    Install Metamask Wallet
                  </a>
                ) : !isConnected ? (
                  <button
                    className="btn deposit_btn"
                    onClick={() => connectWallet()}
                  >
                    <IoMdWallet />
                    Connect Wallet
                  </button>
                ) : Number(chain?.id) !==
                  Number(process.env.REACT_APP_L2_CHAIN_ID) ? (
                  <button className="btn deposit_btn" onClick={handleSwitch}>
                    <HiSwitchHorizontal />
                    Switch to RACE Mainnet
                  </button>
                ) : checkDisabled ? (
                  <button className="btn deposit_btn" disabled={true}>
                    Withdraw
                  </button>
                ) : (
                  <button
                    className="btn deposit_btn"
                    onClick={handlePersonaWithdraw}
                    disabled={loader || Number(withdrawId) === 0 ? true : false}
                  >
                    {loader ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      "Withdraw"
                    )}
                  </button>
                )}
              </div>
              {metaMastError && (
                <small className="d-block text-danger text-center mt-2">
                  {metaMastError}
                </small>
              )}
            </section>
          </>
        )} */}
      </div>
    </>
  );
};

export default Withdraw;
