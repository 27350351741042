// Mapping of chain IDs to their corresponding network names
export const CHAIN_ID_TO_NAME = {
  "0x1": "Ethereum Mainnet", // Chain ID: 1 in hex
  "0xaa36a7": "Sepolia", // Chain ID: 11155111 in hex
  "0x1A96": "RACE Testnet",
  "0x1a96": "RACE Testnet",
  "0x1A95": "RACE Mainnet",
  "0x1a95": "RACE Mainnet",
};

export const CHAIN_ID_TO_RPC = {
  11155111:
    "https://eth-sepolia.g.alchemy.com/v2/UbuTSIBEbFUOY3-y77K-GR38GwUwD1eM",
  6806: "https://racetestnet.io",
  1: "https://eth-mainnet.g.alchemy.com/v2/N3nxBeO3qJpF9OgQEKeh-HlaGD8Y82fi",
  6805: "https://racemainnet.io",
};

// Helper function to get the network name by chain ID
export const getChainName = (chainId) => {
  return CHAIN_ID_TO_NAME[chainId] || `Unsupported Network (${chainId})`;
};
